<template>
    <div>
        <div class="uk-padding-small">
            <vue-good-table
                mode="remote"
                ref="vueTable"
                @on-selected-rows-change="selectionChanged"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                @on-row-click="onRowClick"
                :isLoading.sync="isLoading"
                :pagination-options="pagination"
                :totalRows="remoteData.totalRecords"
                :rows="remoteData.rows"
                :columns="columns"
                :sort-options="{
                    enabled: false,
                }"
                :select-options="{
                    enabled: true,
                    selectionText: 'Intervention sélectionnées',
                    clearSelectionText: 'effacer la sélection',
                }"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <div slot="selected-row-actions">
                    <button class="btn btn-primary" @click="assigment">Affecter</button>
                </div>
                <div slot="table-actions" class="p-2 pt-0 pb-0 d-md-flex flex-row">
                    <div v-if="serverParams.extraFilter.dateRange.startDate" class="d-flex align-items-center dpmargin">
                        <button @click="clearDate" class="btn btn-warning">Effacer filtre date</button>
                    </div>
                    <div class="dpmargin boxDate">
                        <DateRangePicker
                            opens="center"
                            :locale-data="{
                                firstDay: 1,
                                format: 'dd-mm-yyyy',
                                applyLabel: 'Valider',
                                cancelLabel: 'Annuler',
                                daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                                monthNames: [
                                    'Janvier',
                                    'Février',
                                    'Mars',
                                    'Avril',
                                    'Mai',
                                    'Juin',
                                    'Juillet',
                                    'Août',
                                    'Septembre',
                                    'Octobre',
                                    'Novembre',
                                    'Décembre',
                                ],
                            }"
                            class="date-picker-full-height"
                            v-model="serverParams.extraFilter.dateRange"
                            @update="updateValues"
                        />
                        <!-- <i v-if="serverParams.extraFilter.dateRange.startDate" class=" clearDate fa-solid fa-circle-xmark" @click="clearDate"></i> -->
                    </div>
                    <div class="dpmargin">
                        <multiselect
                            v-model="serverParams.extraFilter.status"
                            :options="options"
                            selectedLabel="Sélectionné"
                            selectLabel="Cliquez pour sélectionner"
                            deselectLabel="Cliquez pour désélectionner"
                            :multiple="true"
                            placeholder="Statuts"
                            @input="() => loadItems(true)"
                        >
                        </multiselect>
                    </div>
                    <div class="d-flex align-items-center dpmargin">
                        <button @click="resetFilter" class="btn btn-success">Réinitialiser filtre</button>
                    </div>
                </div>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'actions'" class="uk-text-center">
                        <vk-button
                            @click="
                                $router.push({ name: 'mission_order_view', params: { missionOrderId: props.row.id } })
                            "
                            type="primary"
                            class="uk-border-rounded uk-padding-small uk-padding-remove-top uk-padding-remove-bottom"
                        >
                            <font-awesome-icon icon="fa-regular fa-eye" />
                        </vk-button>
                    </div>
                    <div v-if="props.column.field === 'status'">
                        <span
                            class="uk-label uk-border-rounded"
                            :style="{
                                backgroundColor: getColorForStatus(props.row.status.value),
                            }"
                            v-if="props.row.status"
                            >{{ props.row.status.value }}</span
                        >
                    </div>
                    <div v-else-if="props.column.field === 'osNumero'">
                        <a
                            v-if="isScreenReduced"
                            href="#"
                            @click.prevent="
                                $router.push({ name: 'mission_order_view', params: { missionOrderId: props.row.id } })
                            "
                            v-html="replaceUnderscores(props.row.osNumero)"
                        ></a>
                        <a
                            v-else
                            href="#"
                            @click.prevent="
                                $router.push({ name: 'mission_order_view', params: { missionOrderId: props.row.id } })
                            "
                        >
                            {{ props.row.osNumero }}
                        </a>
                    </div>
                    <div v-else-if="props.column.field === 'g2_inter_code_wording'">
                        <div class="formatted-text mb-3">
                            <a
                                href="#"
                                @click.prevent="
                                    $router.push({
                                        name: 'mission_order_view',
                                        params: { missionOrderId: props.row.id },
                                    })
                                "
                            >
                                {{ props.row.g2_inter_code_wording }}
                            </a>
                        </div>
                        <div v-if="isScreenReduced" style="position: relative">
                            <div
                                v-if="activeTooltipG2InterCodeRow === props.row"
                                class="custom-tooltip g2-inter-code-position"
                            >
                                {{ props.row.g2_inter_code_wording }}
                            </div>
<!--                            <button-->
<!--                                class="btn btn-primary uk-padding-small uk-width uk-margin-small-top uk-margin-small-bottom"-->
<!--                                @click="toggleCustomTooltipG2InterCode(props.row)"-->
<!--                            >-->
<!--                                <i class="fas fa-info-circle fa-lg uk-padding-small"></i>-->
<!--                            </button>-->
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'orderPriority'">
                        <select
                            class="uk-select"
                            v-model="remoteData.rows[props.index].orderPriority"
                            @change="updateOrderPriority(props.row.id, remoteData.rows[props.index].orderPriority)"
                        >
                            <optgroup label="Ordres priorité">
                                <option v-for="(it, idx) in orderPriorities" :key="idx" :value="it">{{ it }}</option>
                            </optgroup>
                        </select>
                    </div>
                    <div v-else-if="props.column.field === 'assignedWorkerUsers'">
                        <div v-for="worker in props.row.assignedWorkerUsers" :key="worker.id">
                            {{ worker.userInfos.firstName + ' ' + worker.userInfos.lastName }}
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'expectedStartDate'">
                        {{ $util.formatDate(props.row.expectedStartDate, 'DD/MM/YYYY', false) }}
                    </div>
                    <div v-else-if="props.column.field === 'property.address.formattedAddress'">
                        <div class="m-2 formatted-text">
                            {{ props.formattedRow[props.column.field] }}
                        </div>
                        <div v-if="isScreenReduced" style="position: relative">
                            <div
                                v-if="activeTooltipFormattedAddressRow === props.row"
                                class="custom-tooltip formatted-address-position"
                            >
                                {{ props.formattedRow[props.column.field] }}
                            </div>
<!--                            <button-->
<!--                                class="btn btn-primary uk-padding-small uk-width uk-margin-small-top uk-margin-small-bottom"-->
<!--                                @click="toggleCustomTooltipFormattedAddress(props.row)"-->
<!--                            >-->
<!--                                <i class="fas fa-info-circle fa-lg uk-padding-small"></i>-->
<!--                            </button>-->
                        </div>
                        <SimpleAccordion v-if="hiddenFields(props.formattedRow).length > 0">
                            <template v-slot:accordionTitle>Plus d'infos</template>
                            <template v-slot:accordionContent>
                                <div class="p-2">
                                    <div v-if="hiddenFields(props.formattedRow).includes('status')" class="mb-3">
                                        <p>Statut:</p>
                                        <div>
                                            <span
                                                class="badge small"
                                                :style="{
                                                    backgroundColor: getColorForStatus(
                                                        props.formattedRow['status']['value'],
                                                    ),
                                                }"
                                            >
                                                {{ props.formattedRow['status']['value'] }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="p-2">
                                    <div v-if="hiddenFields(props.formattedRow).includes('orderPriority')" class="mb-3">
                                        <p>Ordre/Priorité:</p>
                                        <div>
                                            <select
                                                class="uk-select"
                                                v-model="remoteData.rows[props.index].orderPriority"
                                                @change="
                                                    updateOrderPriority(
                                                        props.row.id,
                                                        remoteData.rows[props.index].orderPriority,
                                                    )
                                                "
                                            >
                                                <optgroup label="Ordres priorité">
                                                    <option v-for="(it, idx) in orderPriorities" :key="idx" :value="it">
                                                        {{ it }}
                                                    </option>
                                                </optgroup>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </SimpleAccordion>
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>
        </div>
        <blank-modal ref="modalAffectation" :modal-id="$util.htmlId()">
            <template #modalHeader>
                <legend class="uk-legend">Affectation</legend>
            </template>
            <template #modalBody>
                <vue-good-table
                    ref="vueTableUser"
                    @on-selected-rows-change="selectionUser"
                    :columns="columnsUser"
                    :rows="usersList"
                    :select-options="{
                        enabled: true,
                        selectionText: 'Utilisateurs sélectionnées',
                        clearSelectionText: 'effacer la sélection',
                    }"
                >
                </vue-good-table>
            </template>
            <template #modalFooterExtra>
                <button @click="onSave" class="btn btn-primary">
                    Enregistrer
                    <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                </button>
            </template>
        </blank-modal>
    </div>
</template>

<script>
import backendApi from '@/backend/api'
import store from '@/store'
import SimpleAccordion from '@/components/SimpleAccordion'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import * as Status from '../Enum/Status'
import { mapGetters, mapState } from 'vuex'
import StatusColors from '@/helpers/statusColors'
const OS_NOT_ASSIGNABLE = ['Suspendu définitivement', 'Terminé']
const HIDDEN_COLUMN = ['actions']

export default {
    name: 'Assignement',
    components: { SimpleAccordion, DateRangePicker },
    data: () => ({
        orderPriorities: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        orderPriority: null,
        OsSelected: null,
        loading: false,
        UserSelected: null,
        form: {
            user: null,
        },
        columnsUser: [
            {
                label: 'Utilisateur',
                field: 'username',
            },
            {
                label: 'Prénom',
                field: 'userInfos.firstName',
            },
            {
                label: 'Nom',
                field: 'userInfos.lastName',
            },
        ],
        columnFiltersLocalStorage: {},
        clearComulnFilter: '',
        options: [Status.inProgress, Status.toDo, Status.suspended],
        isLoading: false,
        serverParams: {
            extraFilter: {
                closed: false,
                status: [],
                dateRange: {
                    startDate: new Date(),
                    endDate: '',
                },
            },
            columnFilters: {},
            sort: [],
            page: 1,
            perPage: 10,
        },
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'both',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
        remoteData: {
            rows: [],
            totalRecords: 0,
        },
        intervalId: null,
        isScreenReduced: false,
        activeTooltipG2InterCodeRow: null,
        activeTooltipFormattedAddressRow: null,
    }),
    computed: {
        columns() {
            return this.allColumns.filter((c) => !HIDDEN_COLUMN.includes(c.field))
        },
        allColumns: function () {
            return [
                {
                    label: '#',
                    field: 'osNumero',
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Intervention',
                    field: 'g2_inter_code_wording',
                    hidden: this.shouldBeHidden('xs'),
                    filterOptions: {
                        enabled: true,
                        filterValue: this.clearComulnFilter
                            ? ''
                            : this.columnFiltersLocalStorage
                              ? this.columnFiltersLocalStorage['g2_inter_code_wording']
                              : '',
                    },
                    sortable: true,
                },
                {
                    label: 'Adresse',
                    field: 'property.address.formattedAddress',
                    filterOptions: {
                        enabled: true,
                        filterValue: this.clearComulnFilter
                            ? ''
                            : this.columnFiltersLocalStorage
                              ? this.columnFiltersLocalStorage['property.address.formattedAddress']
                              : '',
                    },
                    sortable: true,
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Date',
                    field: 'expectedStartDate',
                    hidden: this.shouldBeHidden('xs'),
                    sortable: true,
                },
                {
                    label: 'Statut',
                    field: 'status',
                    sortable: true,
                    hidden: this.shouldBeHidden('sm'),
                },
                {
                    label: 'Ordre',
                    field: 'orderPriority',
                    sortable: true,
                    hidden: this.shouldBeHidden('sm'),
                },
                {
                    label: 'Nom',
                    field: 'assignedWorkerUsers',
                    sortable: true,
                    filterOptions: {
                        enabled: true,
                    },
                    hidden: this.shouldBeHidden('xs'),
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: '1%',
                    hidden: true,
                },
            ]
        },
        ...mapState('users', { usersList: 'list', usersListIsUpdating: 'listIsUpdating' }),
        ...mapGetters('osRefreshTime', ['getOsRefreshTimeData']),
    },
    methods: {
        updateOrderPriority(Osid, orderPriority) {
            const data = { orderPriority: orderPriority }
            this.$http
                .updateOrderPriority(Osid, data)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalAffectation.$el).hide()
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Ordre de priorité mis à jour',
                    })
                    this.loadItems()
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        onRowClick(params) {
            if (OS_NOT_ASSIGNABLE.includes(params.row.status.value)) {
                this.$set(params.row, 'vgtSelected', false)
                this.$set(params, 'selected', false)
                params.event.path[0].checked = false
            }
        },
        assigment() {
            if (this.$refs.vueTable.selectedRows.length == 1) {
                const userAssigned = this.$refs.vueTable.selectedRows[0].assignedWorkerUsers.map((u) => u.id)
                for (let i = 0; i < this.usersList.length; i++) {
                    const el = this.usersList[i]
                    if (userAssigned.includes(el.id)) {
                        this.$set(this.usersList[i], 'vgtSelected', true)
                    } else {
                        this.$set(this.usersList[i], 'vgtSelected', false)
                    }
                }
            } else {
                for (let i = 0; i < this.usersList.length; i++) {
                    this.$set(this.usersList[i], 'vgtSelected', false)
                }
            }
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalAffectation.$el).show()
        },
        onSave() {
            const data = {
                missionOrders: this.$refs.vueTable.selectedRows.map((s) => s.id),
                users: this.UserSelected,
            }
            this.$http
                .assigment(data)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalAffectation.$el).hide()
                    this.$store.dispatch('notifications/addNotification', {
                        status: 'success',
                        message: 'Affectation réussie',
                    })
                    this.loadItems()
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .then((this.loading = false))
        },
        selectionChanged() {
            const OsFiltered = this.$refs.vueTable.selectedRows.filter((item) =>
                OS_NOT_ASSIGNABLE.includes(item.status.value),
            )
            this.OsSelected = OsFiltered.map((item) => item.id)
        },
        selectionUser() {
            this.UserSelected = this.$refs.vueTableUser.selectedRows.map((item) => item.id)
        },
        resetFilter() {
            const dateStart = new Date()
            this.serverParams.extraFilter.dateRange.startDate = new Date()
            this.serverParams.extraFilter.dateRange.endDate = new Date(dateStart.setDate(dateStart.getDate() + 5))

            this.serverParams.extraFilter.status = [Status.toDo, Status.suspended]
            this.columnFiltersLocalStorage = null
            localStorage.removeItem('extraFilterStatus')
            localStorage.removeItem('extraFilterDateRangeAssignment')
            localStorage.removeItem('columnFilters')
            this.clearComulnFilter = true
            this.updateValues()
            setTimeout(() => {
                this.clearComulnFilter = undefined
            }, 500)
        },
        shouldBeHidden: function (at) {
            const bp = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'big', 'giga']
            if (!bp.includes(at)) {
                return false
            }

            return !(bp.indexOf(at) <= bp.indexOf(this.$mq))
        },
        hiddenFields: function (formattedRow) {
            let hiddenFields = []
            this.columns.forEach((column) => {
                if (column.hidden) {
                    hiddenFields.push(column.field)
                }
            })

            return hiddenFields
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps)
        },

        onPageChange(params) {
            this.updateParams({ page: params.currentPage })
            this.loadItems()
        },

        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage })
            this.loadItems()
        },
        onSortChange(params) {
            this.updateParams({ sort: params })
            this.loadItems()
        },
        onColumnFilter(params) {
            localStorage.setItem('columnFilters', JSON.stringify(params.columnFilters))
            if (
                params.columnFilters['g2_inter_code_wording'] != '' ||
                params.columnFilters['property.address.formattedAddress'] != '' ||
                params.columnFilters['property.name'] != ''
            ) {
                localStorage.setItem('idUserLocal', this.$store.state.login.user.userData.id)
            }
            this.updateParams(params)
            this.loadItems()
        },
        updateValues(params) {
            this.updateParams(params)
            this.loadItems()
        },
        loadItems(isLoading = true) {
            this.isLoading = isLoading
            backendApi.assigmentList(store.state.login.user.token, this.serverParams).then((data) => {
                this.remoteData.totalRecords = data.totalRecords
                this.remoteData.rows = data.rows
                this.updateParams({
                    page: data.currentPage,
                    perPage: data.maxResult,
                })
            })
        },
        clearDate() {
            this.serverParams.extraFilter.dateRange.startDate = null
            this.serverParams.extraFilter.dateRange.endDate = null
            this.loadItems()
        },
        replaceUnderscores(text) {
            return text.replace(/_/g, '<br>')
        },
        checkScreenSize() {
            this.isScreenReduced = window.innerWidth <= 560
        },
        toggleCustomTooltipG2InterCode(index) {
            this.activeTooltipG2InterCodeRow = this.activeTooltipG2InterCodeRow === index ? null : index
        },
        toggleCustomTooltipFormattedAddress(index) {
            this.activeTooltipFormattedAddressRow = this.activeTooltipFormattedAddressRow === index ? null : index
        },
        getColorForStatus(status) {
            return StatusColors.getColorForStatus(status)
        },
    },
    mounted() {
        this.checkScreenSize()
        window.addEventListener('resize', this.checkScreenSize)

        const datestart = new Date()
        this.serverParams.extraFilter.dateRange.endDate = new Date(datestart.setDate(datestart.getDate() + 5))
        const idUserLocal = localStorage.getItem('idUserLocal')

        if (idUserLocal && this.$store.state.login.user.userData.id == idUserLocal) {
            this.serverParams.extraFilter.status = JSON.parse(localStorage.getItem('extraFilterStatus'))
        }

        if (!localStorage.getItem('extraFilterStatus')) {
            this.serverParams.extraFilter.status = [Status.toDo, Status.suspended]
        }

        const localDateRange = localStorage.getItem('extraFilterDateRangeAssignment')

        if (localDateRange && this.$store.state.login.user.userData.id == idUserLocal) {
            this.serverParams.extraFilter.dateRange = JSON.parse(localDateRange)
        }

        const localColumn = localStorage.getItem('columnFilters')
        if (localColumn) {
            this.columnFiltersLocalStorage =
                this.$store.state.login.user.userData.id == idUserLocal ? JSON.parse(localColumn) : null
        }

        if (!localColumn) {
            this.loadItems()
        }

        this.intervalId = setInterval(() => this.loadItems(false), this.getOsRefreshTimeData * 60000) // Convert minutes to milliseconds
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('users/updateUsersList')
        next()
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('users/updateUsersList')
        next()
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
        window.removeEventListener('resize', this.checkScreenSize)
    },
    watch: {},
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.progress-bar-width {
    width: 140px;
}

.dpmargin {
    margin: 3px;
}

.progress {
    height: 39px;
}

.bg {
    background-color: #a3a3a3;
}

.date-picker-full-height.vue-daterange-picker {
    height: 100%;
    display: flex !important;
    align-items: center;
}

.daterangepicker.show-ranges {
    min-width: auto !important;
}

.daterangepicker.show-calendar .ranges {
    display: none;
}

.boxDate {
    position: relative;
}

.clearDate {
    position: absolute;
    right: -5px;
    top: 0;
    cursor: pointer;
}

@media only screen and (max-width: 560px) {
    .show-calendar {
        position: absolute !important;
        width: 100vw !important;
        height: auto !important;
        margin-top: 100% !important;
    }

    .calendars-container {
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
    }

    .drp-buttons {
        text-align: center !important;
    }

    .formatted-text {
        width: 15ch;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media only screen and (min-width: 760px) {
    .daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked {
        left: 100% !important;
    }
}

.fa-lg {
    font-size: 2.5em !important;
    cursor: pointer !important;
}

.custom-tooltip {
    position: absolute;
    width: 65vw;
    padding: 0.5rem;
    font-size: 1.2rem !important;
    background-color: #333;
    color: #fff;
    border-radius: 2px;
    right: 0;
    bottom: calc(100% + 0.5rem);
}

.g2-inter-code-position {
    left: 0;
}

.formatted-address-position {
    right: 0;
}
</style>
